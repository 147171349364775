.features {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 0;

    .features-wrapper {
        display: flex;
        flex-direction: column;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 80px 20px;
        align-items: center;
    }

    .title {
        font-size: 1.4em;
        font-weight: 700;
        color: #9801cc;
        padding: 0 0 60px 0;
    }

    .features-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: default;

        &.mobile {
            flex-direction: column;
        }

        .features-grid-item {
            background-color: white;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-bottom: 40px;
            flex: 0 0 calc((100% - 80px) / 2);
            transition: transform 0.3s ease-in-out;
            border: solid 1px #576fff;
            display: flex;
            flex-direction: column;

            &.transparent {
                background-color: transparent;
                box-shadow: none;
                justify-content: center;

                .features-cta-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        margin-left: 0 !important;
                    }
                }
            }
    
            .headline {
                font-weight: 700;
                font-size: 1.2em;
                letter-spacing: -0.08rem;
                padding: 20px 20px 0;
                color: #000;
            }
    
            .sub {
                font-size: .9em;
                // color: lighten(#000, 40%);
                color: #576fff;
                padding: 4px 20px 10px;
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }
    
            .description {
                font-size: .9em;
                padding: 0 20px 20px;
                line-height: 20px;
            }

            @media (hover:hover) {
                &:hover:not(.transparent) {
                    transform: scale(1.06);
                }
            }
        }
    }
}