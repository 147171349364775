.navigation {
    flex: 2;
    display: flex;
    justify-content: left;
    gap: 12px;

    &.mobile {
        flex: 1;
        flex-direction: column;

        .nav-element,
        .nav-button {
            color: #3a3a3a !important;

            &.active {
                background-color: #000;
                color: #fff !important;
            }
        }
    }

    .nav-element,
    .nav-button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        transition: all 200ms ease;
        text-decoration: none;
        color: white;

        @media (hover:hover) {
            &:hover {
                background-color: white;
                color: #3a3a3a;
            }
        }

        &.active {
            background-color: white;
            color: #3a3a3a;
        }
    }


}

.cookies-modal {
    z-index: 1001;
    position: absolute;
    margin-right: auto;
    background-color: white;
    padding: 42px;
    border-radius: 6px;
    outline: none;
}

.cookies-modal-header {
    font-size: 20px;
}

.cookies-modal-body {
    padding: 10px 0px 10px 0px;
}

.cookies-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    .cookies-modal-button-primary {
        color: #9801cc;
        border: #ab5cc6;
        border-width: 1px;
        border-style: solid;
    }
}

.cookies-modal-overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}